import React from 'react'

const Footer = () => (
    <footer id="bk-footer" className="page-footer essential-footer bg_color--3 pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5">
        <div className="bk-footer-inner ptb--150 pt_sm--100">
            <div className="container">
                <div className="row">

                    <div className="col-lg-3 col-12">
                        <div className="footer-widget text-var--2">
                            <div className="logo">
                                <a href="#">
                                    <img src="/img/logo.dark.inline.svg" alt="Vertic" />
                                </a>
                                <div className="footer-inner">
                                    <p>&copy; Copyright 2020 Vertic, Inc</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 offset-lg-6 col-12 mt_md--40 mt_md--40 mt_sm--40">
                        <div className="footer-widget text-var--2 menu--instagram">
                            <h2 className="widgettitle">Social</h2>

                            <div className="social-share social--transparent text-white padding-10">
                                <a href="https://www.facebook.com/Vertic-146888042001391"><i className="fab fa-facebook"></i></a>
                                <a href="https://twitter.com/wearevertic"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/wearevertic/"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </footer>
)

export default Footer