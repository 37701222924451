/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import BodyClassName from 'react-body-classname'

import Header from '../../components/header'
import Footer from '../../components/footer'

import '../../styles/style.css'
import '../../styles/modal-style.css'

const Layout = ({ children }) => (
  <div className="wrapper" id="wrapper">
    <Helmet>
      <link rel="stylesheet" href="/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/css/revoulation.css" />
      <link rel="stylesheet" href="/css/plugins.css" />
    </Helmet>
    <BodyClassName className="template-color-23 template-font-7 CerebriSans-font" />

    <div id="page-preloader" className="page-loading clearfix">
        <div className="page-load-inner">
            <div className="preloader-wrap">
                <div className="wrap-2">
                    <div><img src="/img/icons/brook-preloader.gif" alt="Brook Preloader" /></div>
                </div>
            </div>
        </div>
    </div>

    <Header />
    {children}     
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
