import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import slugify from 'slugify'
import _ from 'lodash'

const Header = () => {

    const data = useStaticQuery(graphql`
        query NavQuery {
            contentfulSite(id: {eq: "9361e22b-e848-5460-ad10-74e26177cc1e"}) {
                homepage {
                    id
                }
            }
            allContentfulPage {
                edges {
                    node {
                        id
                        name
                        hiddenInNav
                    }
                }
            }
        }
    `)

    const createUrl = (node) => {
        const homepageId = data.contentfulSite.homepage.id || ''
        let slug = slugify(node.name.toLowerCase())

        node.url = `/${slug}/`

        if (node.id === homepageId) {
            slug = 'index'
            node.url = `/`
        }

        return node
    }

    const renderNavItem = ({ node }) => {
        if (node.hiddenInNav) {
            return null
        }

        node = createUrl(node)

        return (
            <li key={node.id} className="lavel-1"><a href={node.url}><span>{node.name}</span></a></li>
        )
    }

    const renderMobileNavItem = ({node}) => {
        node = createUrl(node)

        return (
            <li><a href={node.url}><span>{node.name}</span></a></li>
        )
    }

    return (
        <React.Fragment>
            <header className="br_header header-default bg_color--23 border-bottom black-logo--version haeder-fixed-width haeder-fixed-150 headroom--sticky header-mega-menu clearfix">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="header__wrapper mr--0">
                                
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            <img src="/img/logo.white.inline.svg" alt="Vertic" />
                                        </a>
                                    </div>
                                </div>
                                
                                <div className="mainmenu-wrapper d-none d-lg-block">
                                    <nav className="page_nav">
                                        <ul className="mainmenu">
                                            {_.map(data.allContentfulPage.edges, renderNavItem)}                                       
                                        </ul>
                                    </nav>
                                </div>
                                
                                <div className="header-right">
                                    
                                    <div className="manu-hamber popup-mobile-click d-block d-lg-none black-version d-block d-xl-none">
                                        <div>
                                            <i></i>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            
            <div className="popup-mobile-manu popup-mobile-visiable">
                <div className="inner">
                    <div className="mobileheader">
                        <div className="logo">
                            <a href="index.html">
                                <img src="/img/logo/brook-black.png" alt="Multipurpose" />
                            </a>
                        </div>
                        <a className="mobile-close" href="#"></a>
                    </div>
                    <div className="menu-content">
                        <ul className="menulist object-custom-menu">
                            {_.map(data.allContentfulPage.edges, renderMobileNavItem)} 
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Header